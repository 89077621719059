import { useEffect, useState } from "react";
import { ArrowRightIcon, RefreshIcon } from "@heroicons/react/solid";
import { Link, useMatch } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function randomArrayShuffle(array = []) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

async function fetchCollections() {
  const isDev = process.env.NODE_ENV === "development";
  const endpoint = isDev ? "https://arikianggi.id" : "";
  const res = await fetch(`${endpoint}/.netlify/functions/collection`);
  const json = await res.json();
  return json;
}

function Collection() {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const match = useMatch("/Collection");
  const isCollectionPage = match !== null;

  useEffect(() => {
    void fetchCollections().then((data) => {
      setItems(randomArrayShuffle(data));
      setIsLoading(false);
    });
  }, []);

  // const handleSearch = () => {
  //   alert("Search functionality is not implemented yet!");
  //   // Implement actual search functionality here
  // };
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="transition-all duration-1000 ease-in-out bg-gray-100 dark:bg-black dark:bg-opacity-95 dark:border-t dark:border-neutral-500" id="Collection">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl py-16 mx-auto sm:py-24 lg:py-32 lg:max-w-none">
          <div className="mb-5">
            <h2 className="inline-block text-2xl font-bold text-gray-900 align-top dark:text-neutral-300">
              Student Source Code Collections
            </h2>
            {isCollectionPage && (
              <div className="inline-block float-right mt-3 mb-5 align-top">
              <input
                className="px-3 mr-1 align-middle border rounded-md shadow-md h-9 w-36 sm:w-60 xl:w-80 rounded-l-3xl dark:shadow-gray-500 dark:bg-opacity-10"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            )}
          </div>
          {(isLoading || items.length <= 0) && (
            <div className="flex flex-col items-center justify-center px-8 py-16 space-y-4 text-gray-500">
              <RefreshIcon className="w-12 h-12 animate-spin" />
              <span className="animate-pulse">Loading...</span>
            </div>
          )}
          {(!isLoading || items.length > 0) && (
            <div className="container grid flex-wrap grid-cols-2 mx-auto my-6 space-y-0 md:space-y-0 md:grid-cols-3 lg:gap-x-6 gap-x-6 ">
              {items
                .filter((item) =>
                  item.Name.toLowerCase().includes(searchTerm.toLowerCase()) || item.Description.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .slice(0, isCollectionPage ? items.length : 3)
                .map((item) => (
                  <div key={item.name} className="relative mb-5 group hover:shadow-md" data-aos="fade-up">
                    <div className="relative w-full overflow-hidden bg-white rounded-lg group-hover:opacity-75">
                      <img
                        src={item.Images[0].thumbnails.large.url}
                        alt={item.Description}
                        className="object-cover object-center w-full h-64 transition-transform duration-1000 ease-in-out group-hover:scale-90"
                      />
                    </div>
                    <h3 className="mt-6 text-sm text-gray-500 dark:text-gray-200">
                      <a
                        href={item.URL || "#"}
                        target={item.href === "#" ? "" : "_blank"}
                        rel="noreferrer"
                        className="before:absolute before:inset-0 before:content-['']"
                      >
                        {item.Name}
                      </a>
                    </h3>
                    <p className="mb-5 text-base font-semibold text-gray-900 dark:text-gray-500">
                      {item.Description}
                    </p>
                  </div>
                ))}
            </div>
          )}

          {!isCollectionPage && (
            <div className="flex items-center justify-center">
              <Link
                to="/Collection"
                className="flex items-center px-4 py-2 space-x-2 font-bold text-white transition-all duration-500 ease-in-out bg-indigo-600 border border-blue-700 hover:bg-blue-500 rounded-xl dark:bg-white dark:bg-opacity-10 dark:hover:bg-opacity-20"
              >
                <span>Show more</span>
                <ArrowRightIcon className="w-4 h-4" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Collection;