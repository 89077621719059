import React from "react";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full"
          : "relative shadow-lg bg-white ") +
        " flex flex-wrap items-center justify-between px-2 py-3 rounded-b-xl"
      }
    >
      <div className="container flex flex-wrap items-center justify-between px-4 mx-auto">
        <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className={
              (props.transparent ? "text-white " : "text-indigo-600") +
              " text-lg font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase font-sans"
            }
            href="/"
          >
            Subkids
          </a>
          <button
            className={"block px-3 py-1 text-xl leading-none bg-indigo-600 border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none transition-all ease-in-out" + (navbarOpen ? " rotate-90 bg-indigo-400" : "rotate-180")}
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i
              className={
                (props.transparent ? "text-white" : "text-white") +
                " fas fa-bars"
              }
            >=</i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center   lg:bg-transparent lg:shadow-none" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >
          
          <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
            

            <li className="flex items-end">
              <button
                className={
                  (props.transparent
                    ? "bg-white text-gray-800 active:bg-gray-100"
                    : "bg-indigo-600 text-white active:bg-pink-600") +
                  " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-auto mr-3 my-3"
                }
                type="button"
                style={{ transition: "all .5s ease" }}
              >
                <i className="fas fa-arrow-alt-circle-down"></i> Sign Up
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}