import foto from "./pic/ms-icon-512x310.png";
import white from "./pic/white-icon-512x310.png";

import "aos/dist/aos.css";

function Footer() {
  return (
    <>
      <div className="py-12 transition-all duration-1000 ease-in-out bg-cover bg-gray-50 dark:bg-blackpattern dark:border-t dark:border-neutral-500 " id="Footer">
        <div className="px-4 mx-auto mt-6 space-y-12 max-w-7xl sm:px-6 lg:px-8 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6 sm:space-y-0 sm:grid sm:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div className="flex flex-col justify-between sm:inline-block" data-aos="fade-right" data-aos-anchor-placement="top-bottom">
            <div className="flex justify-center mb-4 xl:justify-start ">
              <img
                src={foto}
                alt="icon"
                className="inline-block object-center mr-4 cursor-pointer h-9 w-7 dark:hidden animate-pulse"
              />
              <img
                src={white}
                alt="icon"
                className="hidden object-center mr-4 cursor-pointer h-9 w-7 dark:inline-block animate-pulse"
              />
              <p className="inline-block text-2xl font-bold font-press-start dark:text-neutral-300">
                Let's Keep in touch
              </p>
            </div>
            <ul className="relative font-bold leading-8 dark:text-neutral-300 ">
              <li className="transition-all hover:underline hover:translate-x-2">
                <a href="mailto:hello@arikianggi.id">hello@arikianggi.id</a>
              </li>
              <li className="transition-all hover:underline hover:translate-x-2">
                <a href="https://api.whatsapp.com/send?phone=62895804624286">+62 895804624286</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col justify-evenly sm:inline-block dark:text-neutral-300" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <h2 className="inline-block text-xl font-bold sm:mb-4 font-press-start">
              Other Products
            </h2>

            <ul className="relative leading-8">
              <li className="transition-all hover:underline hover:translate-x-2 hover:font-bold">
                <a href="https://arikianggi.id/subkids" target="_blank" rel="noreferrer">Subkids</a>
              </li>
              <li className="transition-all hover:underline hover:translate-x-2 hover:font-bold">
                <a href="#Footer">MavisNewt</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col-reverse bg-center bg-no-repeat bg-cover h-cover w-cover " data-aos="fade-left" data-aos-anchor-placement="top-bottom">
            <div className="grid space-y-6 place-items-start lg:mx-auto sm:my-auto rounded-3xl sm:place-items-center sm:mt-10 lg:mt-0">
              <h2 className="inline-block text-xl font-bold font-press-start dark:text-neutral-300">
                Social Media
              </h2>
              <div className="flex items-start justify-center space-x-3">
                <a
                  href="https://www.facebook.com/ariki.anggi.3"
                  target="_blank"
                  rel="noreferrer"
                  className="relative group"
                >
                  <button className="inline-flex items-center p-2 space-x-2 font-semibold text-white bg-blue-500 rounded ">
                    <svg
                      className="w-5 h-5 fill-current group-hover:opacity-80 "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                    </svg>
                    <span className="absolute invisible p-2 text-xs text-white transition-all duration-1000 ease-in-out transform -translate-x-1/2 bg-gray-800 rounded-md opacity-0 bottom-11 left-1/2 group-hover:opacity-100 group-hover:visible">
                      Facebook
                    </span>
                  </button>
                </a>
                <a
                  href="https://twitter.com/arikianggi"
                  target="_blank"
                  rel="noreferrer"
                  className="relative group"
                >
                  <button className="inline-flex items-center p-2 space-x-2 font-semibold text-white bg-blue-400 rounded">
                    <svg
                      className="w-5 h-5 fill-current group-hover:opacity-80 "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                    </svg>
                    <span className="absolute invisible p-2 text-xs text-white transition-all duration-1000 ease-in-out transform -translate-x-1/2 bg-gray-800 rounded-md opacity-0 bottom-11 left-1/2 group-hover:opacity-100 group-hover:visible">
                      Twitter
                    </span>
                  </button>
                </a>
                <a
                  href="https://www.linkedin.com/in/ariki-anggi-231bb9166"
                  target="_blank"
                  rel="noreferrer"
                  className="relative group"
                >
                  <button className="inline-flex items-center p-2 space-x-2 font-semibold text-white bg-blue-600 rounded">
                    <svg
                      className="w-5 h-5 fill-current group-hover:opacity-80"
                      role="img"
                      viewBox="0 0 256 256"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path d="M218.123122,218.127392 L180.191928,218.127392 L180.191928,158.724263 C180.191928,144.559023 179.939053,126.323993 160.463756,126.323993 C140.707926,126.323993 137.685284,141.757585 137.685284,157.692986 L137.685284,218.123441 L99.7540894,218.123441 L99.7540894,95.9665207 L136.168036,95.9665207 L136.168036,112.660562 L136.677736,112.660562 C144.102746,99.9650027 157.908637,92.3824528 172.605689,92.9280076 C211.050535,92.9280076 218.138927,118.216023 218.138927,151.114151 L218.123122,218.127392 Z M56.9550587,79.2685282 C44.7981969,79.2707099 34.9413443,69.4171797 34.9391618,57.260052 C34.93698,45.1029244 44.7902948,35.2458562 56.9471566,35.2436736 C69.1040185,35.2414916 78.9608713,45.0950217 78.963054,57.2521493 C78.9641017,63.090208 76.6459976,68.6895714 72.5186979,72.8184433 C68.3913982,76.9473153 62.7929898,79.26748 56.9550587,79.2685282 M75.9206558,218.127392 L37.94995,218.127392 L37.94995,95.9665207 L75.9206558,95.9665207 L75.9206558,218.127392 Z M237.033403,0.0182577091 L18.8895249,0.0182577091 C8.57959469,-0.0980923971 0.124827038,8.16056231 -0.001,18.4706066 L-0.001,237.524091 C0.120519052,247.839103 8.57460631,256.105934 18.8895249,255.9977 L237.033403,255.9977 C247.368728,256.125818 255.855922,247.859464 255.999,237.524091 L255.999,18.4548016 C255.851624,8.12438979 247.363742,-0.133792868 237.033403,0.000790807055"></path>
                      </g>
                    </svg>
                    <span className="absolute invisible p-2 text-xs text-white transition-all duration-1000 ease-in-out transform -translate-x-1/2 bg-gray-800 rounded-md opacity-0 bottom-11 left-1/2 group-hover:opacity-100 group-hover:visible">
                      LinkedIn
                    </span>
                  </button>
                </a>
                <a 
                  href="https://www.tiktok.com/@arikianggi.id" 
                  className="relative group"
                  target="_blank"
                  rel="noreferrer"
                  >
                  <button className="inline-flex items-center p-2 space-x-2 font-semibold text-white bg-black rounded dark:border dark:border-blue-700 stroke-violet-200">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="w-5 h-5 fill-current group-hover:opacity-80"
                      viewBox="0 0 16 16">
                      <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" /> </svg>
                      <span className="absolute invisible p-2 text-xs text-white transition-all duration-1000 ease-in-out transform -translate-x-1/2 bg-gray-800 rounded-md opacity-0 bottom-11 left-1/2 group-hover:opacity-100 group-hover:visible">
                      Tiktok
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto text-gray-500 align-bottom bg-gray-100 dark:bg-black">
        <p className="mx-auto text-center border-t-2 max-w-7xl dark:border-neutral-500">
          Made using{" "}
          <a className="text-indigo-600 underline" href="https://reactjs.org/">
            React.js
          </a>{" "}
          and{" "}
          <a
            className="text-indigo-600 underline"
            href="https://tailwindcss.com/"
          >
            Tailwind CSS
          </a>
          . Hosted on{" "}
          <a
            className="text-indigo-600 underline"
            href="https://www.netlify.com/"
          >
            Netlify
          </a>
          .
        </p>
      </div>
      <div className="h-20 bg-gray-100 dark:bg-black md:hidden"></div>
    </>
  );
}

export default Footer;
