import image from "./pic/source/hero-image.jpg"

export default function Hero() {
  return (
    <>
      <div className="flex flex-wrap px-20 py-24 bg-slate-50">
        <div className="flex items-center w-full lg:w-1/2">
          <div className="max-w-2xl mb-8">
            <h1 className="text-4xl font-bold leading-snug tracking-tight text-gray-800 lg:text-4xl lg:leading-tight xl:text-7xl xl:leading-tight dark:text-white">
              Learn Coding Fun
            </h1>
            <h2 className="text-xl font-bold leading-snug tracking-tight text-indigo-600 lg:text-xl lg:leading-tight xl:text-3xl xl:leading-tight dark:text-white">
              Short Course Web building - HTML & CSS
            </h2>
            <p className="py-5 text-xl leading-normal text-gray-500 lg:text-xl xl:text-2xl dark:text-gray-300">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis officia ullam iusto ratione autem quaerat nobis dicta debitis reiciendis dolore accusantium saepe minima illum suscipit nulla, quam odit omnis rerum!
            </p>

            <div className="flex flex-col items-start space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row">
              <a
                href="https://web3templates.com/templates/nextly-landing-page-template-for-startups"
                rel="noopener"
                className="px-8 py-4 text-lg font-medium text-center text-white bg-indigo-600 rounded-md ">
                Register
              </a>
              
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-full lg:w-1/2">
          <div className="">
            <img
              className="bg-cover"
              src={image}
              width="616"
              height="617"
              alt="Hero Illustration"
              layout="intrinsic"
              loading="eager"
              placeholder="blur"
            />
          </div>
        </div>
      </div>
      
    </>
  );
}