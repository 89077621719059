import image from "./pic/source/hero-image.jpg"

function Section() {
  return (
    <>
      <div className="flex flex-wrap px-20 py-5">
        <div className="flex items-center justify-center w-full lg:w-1/2">
          <div className="">
            <img
              className="bg-cover"
              src={image}
              width="616"
              height="617"
              alt="Hero Illustration"
              layout="intrinsic"
              loading="eager"
              placeholder="blur"
            />
          </div>
        </div>
        <div className="flex items-center w-full lg:w-1/2">
          <div className="max-w-2xl mb-8">
            <h1 className="text-4xl font-bold leading-snug tracking-tight text-gray-800 lg:text-4xl lg:leading-tight xl:text-3xl xl:leading-tight dark:text-white">
              What is Short Course Web Building ?
            </h1>
            
            <p className="py-5 text-xl leading-normal text-gray-500 lg:text-xl xl:text-2xl dark:text-gray-300">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis officia ullam iusto ratione autem quaerat nobis dicta debitis reiciendis dolore accusantium saepe minima illum suscipit nulla, quam odit omnis rerum!
            </p>

            
          </div>
        </div>
        
      </div>
      
    </>
  );
}

export default Section;