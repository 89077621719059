import React from "react";

function Register() {
  return (
    <div className="items-center py-10 bg-cover -center bg-slate-50" id="contact">
      <div className="flex flex-col items-center w-2/5 py-10 mx-auto bg-indigo-600 rounded-md justify-evenly">
        <h2 className="mt-0 mb-10 text-2xl font-bold text-white">Registration Forms.</h2>
        <form className="flex flex-col items-center m-auto rounded-md h-4/5 justify-evenly " data-netlify="true" method="POST" name="contact">
          
          <p>
            <input id="name" className="px-3 py-2 my-2 leading-tight text-gray-700 border rounded shadow appearance-none lg:w-72 focus:outline-none"  name="name" placeholder="Student Name" required type="text" />
          </p>

          <p>
            <input id="phone" className="px-3 py-2 my-2 leading-tight text-gray-700 border rounded shadow appearance-none lg:w-72 focus:outline-none focus:shadow-outline" name="phone" placeholder="Phone Number" required type="tel" />
          </p>
          <p className="text-white">
            Choose a Plan:
            <select className="block px-4 py-2 pr-8 my-2 leading-tight text-black bg-white border border-gray-400 rounded shadow appearance-none w-60 hover:border-gray-500 focus:outline-none focus:shadow-outline" id="plan" name="plan">
              <option value="Regular">Basic Plan</option>
              <option value="Warrior">Warrior plan</option>
              <option value="Group">Group Plan</option>
            </select>
          </p>
          <p>
            <textarea className="h-16 px-2 py-1 my-2 mr-3 leading-tight text-gray-700 bg-white rounded-sm w-72" id="message" name="message" placeholder="Student Description" required />
          </p>
          <p>
            <button className="flex-shrink-0 px-2 py-1 text-sm text-white bg-teal-500 border-4 border-teal-500 rounded hover:bg-teal-700 hover:border-teal-700" type="submit">Submit</button>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Register;