/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UploadIcon } from "@heroicons/react/outline";

// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import white from "./pic/white-icon-512x310.png";

const navigation = [
  { name: "Home", href: "#", link: "/" },
  { name: "Collection", href: "#Collection", link: "/Collection" },
  { name: "Experience", href: "#Experience", link: "/Experience" },
  { name: "About", href: "#About", link: "/About" },
];

// eslint-disable-next-line
function Hero(image) {
  const [Theme, setTheme] = useState("light");
  useEffect(() => {
    if(window.matchMedia('(prefers-color-scheme: dark)').matches){
      setTheme("dark");
    }else{
      setTheme("light");
    }
  },[]);
  useEffect(() => {
    if (Theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [Theme]);
  
  const handleswitch = () => {
    setTheme(Theme === "dark" ? "light" : "dark");
  };
  return (
    <div className="relative overflow-hidden transition-all duration-1000 ease-in-out bg-white bg-cover dark:bg-blackpattern">
      <div className="mx-auto max-w-7xl">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 dark:bg-black">
          <svg
            className="absolute inset-y-0 right-0 hidden w-48 h-full text-white transform translate-x-1/2 lg:block dark:text-black"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,50 50,100 50,100" />{" "}
            {/* "50,0 100,0 50,100 0,100" */}
          </svg>

          <Popover>
            <div className="relative top-0 px-4 pt-6 sm:px-6 lg:px-8 lg:left-16 md:left-44">
              <nav
                className="fixed flex items-center justify-between py-6 transition-all duration-1000 ease-in-out bg-indigo-600 shadow-md rounded-xl to-transparent sm:h-10 lg:justify-start dark:bg-white dark:bg-opacity-20 bg-opacity-80 backdrop-blur-sm shadow-black/50"
                aria-label="Global"
              >
                <div className="hidden py-2 space-y-1 md:block md:ml-10 md:pr-4 md:space-x-8">
                  
                    <img
                      className="inline-block m-0 cursor-pointer w-7 animate-pulse" 
                      src={white}
                      alt="Logo"
                    />
                  {navigation.map((item) => (
                    <Link to={item.link} onClick={window.scrollTo(0, 0)}>
                      <a
                        key={item.name}
                        href={item.href}
                        className="font-medium text-gray-50 hover:text-gray-300"
                      >
                        {item.name}
                      </a>
                    </Link>
                  ))}
                </div>
              </nav>
            </div>

            <div className="fixed inset-x-0 z-10 items-stretch p-2 mx-auto transition origin-top-right transform bottom-16 md:hidden">
              <div className="absolute overflow-hidden bg-indigo-600 shadow-lg w-fit rounded-xl ring-1 ring-black ring-opacity-5 bg-opacity-80 backdrop-blur-sm shadow-black/50">
                <div className="flex items-center justify-between px-5"></div>
                <div className="px-2 pt-2 pb-3 space-y-1 divide-x">
                  {navigation.map((item) => (
                    <Link to={item.link} onClick={window.scrollTo(0, 0)}>
                      <a
                        key={item.name}
                        href={item.href}
                        className="inline-flex px-3 py-2 text-base font-medium text-gray-100 hover:text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Popover>
          <div className="fixed block p-2 text-sm transition-all duration-500 ease-in-out bg-black rounded-lg shadow-lg w-13 h-13 text-slate-100 dark:bg-white dark:text-black top-2 right-2 sm:hidden">
            <button onClick={handleswitch}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="invisible w-6 h-6 dark:visible dark:block">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="visible block w-6 h-6 dark:invisible dark:hidden">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
              </svg>
            </button>
          </div>
          <div className="fixed hidden p-2 text-sm transition-all duration-500 ease-in-out bg-black rounded-lg shadow-lg right-5 w-13 h-13 text-slate-100 bottom-5 dark:bg-white dark:text-black sm:block">
            <button onClick={handleswitch}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="invisible w-6 h-6 dark:visible dark:block">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="visible block w-6 h-6 dark:invisible dark:hidden">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
              </svg>
            </button>
          </div>
          <main className="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28" data-aos="fade-right">
            <div className="text-center lg:text-left">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight dark:text-neutral-300">
                <span className="block transition-all duration-200 ease-in-out cursor-pointer hover:underline hover:translate-x-10 dark:text-neutral-300">Student Collection</span>{" "}
              </h1>
              <p className="mt-3 leading-none text-justify text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-l lg:mx-0">
                This webpage functions as a gallery showcasing a collection of projects developed by my students. It is important to note that all the source code featured on this page was independently created by the students themselves.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                <div className="rounded-md shadow "> 
                  <a
                    href="https://arikianggi.id/submit-project" 
                    className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white transition-all duration-500 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 dark:bg-white dark:bg-opacity-10 dark:hover:border-blue-700 md:py-4 md:text-lg md:px-10 dark:hover:bg-opacity-20"
                  >
                    <UploadIcon className="w-5 h-5 mr-1"/>
                    Submit Project
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="hidden md:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 " data-aos="zoom-out">
        <img
          className="object-cover w-full h-56 transition-all duration-1000 ease-in-out sm:h-72 md:h-96 lg:w-full lg:h-full hover:object-bottom"
          src={image.img}
          alt=""
        />
      </div>
    </div>
  );
}

export default Hero;
