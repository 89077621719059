import AOS from "aos";
import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";

const features = [
  { name: "Born", description: "December 24th, 1996; Surabaya, Indonesia." },
  {
    name: "Graduate",
    description:
      "Bachelor Degree on Informatics at Institute Teknologi Adhi Tama Surabaya on 2019.",
  },
  {
    name: "Programming language",
    description: "Python, C++, React.js, TailwindCSS, SQL.",
  },
  { name: "Interest", description: "Expanding my knowledge and skills in algorithms" },
];

const image = [
  {
    imgsrc:
      "https://lh3.googleusercontent.com/DVQzBgF8WjC-UvvKGxueSipDkEyo4Fsdw_FbzBaiMN7unI20G23I084xfyx4Lo_9i4U=w2400",
    imgalt: "Me Close-up",
  },
  {
    imgsrc:
      "https://lh6.googleusercontent.com/t-AJu3AYgijEvGx3pvMEok0NJDWtAY2DRQc7SWbi2T39RSdEArO5R-kov5NpF4P7KvI=w2400",
    imgalt: "My Desk setup",
  },
  {
    imgsrc:
      "https://raw.githubusercontent.com/arikianggi/my-port/01af0ee8856e8a807dec5ae970b979b1f1645640/src/pic/new.png?token=GHSAT0AAAAAACWOHPD4RONHTS54TPD3STZGZWOBPLQ",
    imgalt: "Prewed",
  },
  {
    imgsrc:
      "https://lh5.googleusercontent.com/eixZQLw7nTtUHHyzYWLIZmhOtKkf_LpYO0L1A5o8fM87YC2xBLO_oIYhZ5IuVkanhcU=w2400",
    imgalt: "Me and my parents",
  },
];

const shapeClasses = [
  "rounded-over",
  "rounded-dee",
  "rounded-leaf",
  "rounded-leaf2",
  "rounded-wathover",
  "rounded-water",
];

const getRandomShapeClass = () => {
  return shapeClasses[Math.floor(Math.random() * shapeClasses.length)];
};

function About() {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });

    // Handle ESC key to close modal
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setSelectedImage(null);
      }
    };
    window.addEventListener("keydown", handleEscKey);

    return () => {
      window.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (event.target.className.includes("fixed")) {
      setSelectedImage(null);
    }
  };

  return (
    <div
      className="transition-all duration-1000 ease-in-out bg-gray-100 dark:bg-black dark:border-t dark:border-neutral-500 dark:bg-opacity-95"
      id="About"
    >
      <div
        className="grid items-center max-w-2xl grid-cols-1 px-4 py-24 mx-auto gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2"
        data-aos="fade-right"
      >
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:tracking-tight sm:text-4xl dark:text-neutral-300">
            About Myself
          </h2>
          <p className="mt-4 text-justify text-gray-500">
            Hi! My name is{" "}
            <span className="font-bold underline">Ariki Anggi Handriyan</span>,
            commonly referred to as <span className="font-bold">Ariki</span> for short.
          </p>
          <p className="mt-4 text-justify text-gray-500">
            With over three years of experience as a Coding Instructor, I have
            worked with students ranging from Elementary to High School, as well
            as those preparing for college. My profound interest in the field of
            computer science has motivated me to collaborate with a school
            company, allowing me to inspire a greater number of students to
            pursue studies in this field. The primary objective of assuming the
            role of a Coding Instructor is{" "}
            <span className="font-bold ">
              to introduce the fundamentals of computer science to children at
              an early stage
            </span>
            , imparting essential coding skills.
          </p>
          <p className="mt-4 text-justify text-gray-500">
            This instructional approach not only introduces students to the
            basics of coding but also explains how Software Engineers use
            mathematical concepts to solve problems in{" "}
            <span className="font-bold ">logical</span> and{" "}
            <span className="font-bold ">creative</span> manners. As students
            progress to intermediate levels, they are poised to explore the
            practical application of algorithms.
          </p>

          <dl className="grid grid-cols-1 mt-16 gap-x-6 gap-y-6 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="pt-4 border-t border-indigo-600 dark:border-blue-700"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <dt className="font-medium text-gray-900 dark:text-neutral-300">
                  {feature.name}
                </dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div
          className="grid grid-cols-2 gap-2 sm:gap-6 lg:gap-8"
          data-aos="fade-left"
          data-aos-anchor-placement="top-bottom"
        >
          {image.map((data) => (
            <img
              key={data.imgalt}
              src={data.imgsrc}
              alt={data.imgalt}
              className={`transition-all duration-500 ease-in-out hover:rounded-3xl bg-gray-100 shadow-2xl cursor-pointer hover:scale-110 ${getRandomShapeClass()}`}
              onClick={() => setSelectedImage(data.imgsrc)}
            />
          ))}
        </div>
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80"
          onClick={handleClickOutside}
        >
          <div className="relative">
            <img src={selectedImage} alt="Enlarged" className="md:max-w-3xl md:max-h-3xl sm:max-w-xl sm:max-h-xl" />
            <button
              className="absolute bottom-5 right-1/2 text-white bg-gray-800 rounded-full p-2"
              onClick={() => setSelectedImage(null)}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default About;
